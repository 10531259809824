import { Asset } from "@/components/bloks/MediaBlok";
import { Tag } from "@/components/ui/Tag";
import { Text } from "@/components/ui/Text";
import type { AssetStoryblok } from "@/generated/sb/sb-types";

import Link from "next/link";
import styles from "./index.module.css";

export type ArticleCardProps = Partial<{
	tags: string[];
	image: AssetStoryblok;
	title: string;
	shortDescription: string;
	slug: string;
}>;

export const ArticleCard = ({
	title,
	image,
	shortDescription,
	tags,
	slug,
}: ArticleCardProps) => {
	return (
		<Link href={slug ?? "/"} className={styles.root}>
			<div className={styles.cardBody}>
				<div className={styles.overlay}>
					{tags &&
						!!tags.length &&
						tags.map((tag) => (
							<Tag variant="white" className={styles.cardTag} size="small" key={tag}>
								{tag}
							</Tag>
						))}
				</div>
				{image && <Asset source={image} />}
			</div>

			<div className={styles.cardFooter}>
				<dl className={styles.footerInfo}>
					<dt className="sr-only">Article title</dt>
					<Text variant="h4" bold asChild>
						<dd>{title}</dd>
					</Text>
					<dt className="sr-only">Description</dt>
					<Text variant="body2" asChild>
						<dd>{shortDescription}</dd>
					</Text>
				</dl>
			</div>
		</Link>
	);
};
