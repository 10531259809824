"use client";

import { Chevron } from "@/components/icons";
import { clsx, Slot } from "@frend-digital/ui";
import { useControllableState } from "@frend-digital/ui/hooks";
import { useResolvedId } from "move-to-front/ui/hooks";
import {
	createContext,
	use,
	useState,
	type ComponentProps,
	type Dispatch,
	type SetStateAction,
} from "react";
import { Button } from "../button";
import { Text } from "../Text";
import styles from "./index.module.css";

type ShowMoreProps = ComponentProps<"div"> & {
	open?: boolean;
	onOpenChange?: Dispatch<SetStateAction<boolean>>;
};

interface ShowMoreContext {
	id: string;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

const ShowMoreContext = createContext<ShowMoreContext>(null!);

export const ShowMoreRoot = ({
	children,
	className,
	open,
	onOpenChange,
	...rest
}: ShowMoreProps) => {
	const id = useResolvedId(rest.id);
	const [showMore, setShowMore] = useControllableState(false, open, onOpenChange);

	return (
		<ShowMoreContext.Provider value={{ open: showMore, setOpen: setShowMore, id }}>
			<div
				data-state={showMore ? "open" : "closed"}
				data-target={id}
				className={clsx(className, "show-more-root")}
				{...rest}>
				{children}
			</div>
		</ShowMoreContext.Provider>
	);
};

export const ShowMoreContent = ({
	className,
	fade,
	children,
	collapsedHeight: maxHeight,
	...props
}: ComponentProps<"div"> & {
	fade?: boolean;
	collapsedHeight?: number | string;
}) => {
	const { open, id } = use(ShowMoreContext);

	return (
		<div
			id={id}
			role="region"
			data-state={open ? "open" : "closed"}
			className={clsx(styles.root, className, fade && styles.fadeY, "show-more-content")}
			{...props}
			style={
				{
					...props.style,
					"--collapsed-height":
						typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight || undefined,
				} as React.CSSProperties
			}>
			{children}
		</div>
	);
};

export const ShowMoreTrigger = ({
	children,
	className,
	indicator,
	openText,
	asChild,
	closeText,
	...rest
}: Omit<ComponentProps<"button">, "color"> & {
	indicator?: boolean;
	asChild?: boolean;
	openText?: React.ReactNode;
	closeText?: React.ReactNode;
}) => {
	const { open, setOpen, id } = use(ShowMoreContext);

	const renderContent = () => {
		if (open && closeText) {
			return closeText;
		}
		if (!open && openText) {
			return openText;
		}
		return children;
	};

	return (
		<Button
			aria-expanded={open}
			aria-controls={id}
			variant="unstyled"
			onClick={() => setOpen(!open)}
			className={clsx(styles.viewButton, className)}
			{...rest}>
			{indicator && (
				<Chevron
					rotateanimation={{
						trigger: open,
						deg: 180,
					}}
					className={clsx(open && styles.chevronExpanded)}
				/>
			)}
			{!openText && !closeText ? (
				<Text variant="h5" asChild={asChild}>
					{renderContent()}
				</Text>
			) : (
				renderContent()
			)}
		</Button>
	);
};

export const ReadMore = ({
	asChild,
	children,
	...props
}: ComponentProps<"div"> & {
	asChild?: boolean;
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const Component = asChild ? Slot : "p";

	return (
		<div {...props}>
			<Component className={clsx(styles.readMore, isExpanded && styles.open)}>
				{children}
			</Component>
			<button onClick={toggleReadMore} className={styles.readMoreButton}>
				{isExpanded ? "Show less" : "Read more"}
			</button>
		</div>
	);
};
