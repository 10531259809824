"use client";

import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import { useIntersectionObserverAPI } from "@frend-digital/ui/hooks";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { useState } from "react";
import type { fetchAricles, FetchArticlesReturn } from "./actions";
import { ArticleCardSkeleton } from "./ArticleCard";
import { ArticleGrid } from "./ArticleGrid";
import { ARTICLES_PER_PAGE } from "./config";
import styles from "./index.module.css";

export const Sentinel = ({ action }: { action: () => void }) => {
	const obserrver = useIntersectionObserverAPI(([entry]) => {
		if (entry?.isIntersecting) {
			action();
		}
	});

	return (
		<span
			ref={(element) => {
				obserrver?.observe(element!);
				return () => {
					obserrver?.disconnect();
				};
			}}
		/>
	);
};

export const InfiniteArticleView = ({
	initialData,
	action,
	options,
}: {
	initialData: FetchArticlesReturn;
	action: typeof fetchAricles;
	options: {
		filter: string | string[] | undefined;
		slug: string[];
		locale: string;
	};
}) => {
	const t = useTranslations("articles");
	const [isAutoLoadEnabled, setIsAutoLoadEnabled] = useState<boolean>(false);

	const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery({
		queryKey: ["fetchArticles", options],
		queryFn: async ({ pageParam }) => {
			const data = await action({
				...options,
				page: pageParam + 1,
			});
			return data;
		},
		initialData: {
			pageParams: [1],
			pages: [initialData],
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) =>
			lastPage?.total! > ARTICLES_PER_PAGE * allPages.length
				? allPages.length
				: undefined,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const initialLoadedArticles = (data.pages[0]?.component as unknown as any[]).length;
	const totalAvailableArticles = Number(data.pages[0]?.total);
	const isLoadMoreAvailable =
		totalAvailableArticles !== initialLoadedArticles && hasNextPage;

	const handleClick = () => {
		setIsAutoLoadEnabled(true);
		fetchNextPage();
	};

	return (
		<>
			<ArticleGrid>
				{data?.pages.map((page) => page?.component)}
				{isFetching && <ArticleCardsSkeleton />}
			</ArticleGrid>
			{isAutoLoadEnabled && hasNextPage ? (
				<Sentinel action={fetchNextPage} />
			) : isLoadMoreAvailable && !isAutoLoadEnabled ? (
				<div className={styles.showAll}>
					<Button size="xLarge" color="white" onClick={handleClick} disabled={isLoading}>
						{t("showAll")}{" "}
						<AmountDot variant="beige">
							{totalAvailableArticles - initialLoadedArticles}
						</AmountDot>
					</Button>
				</div>
			) : null}
		</>
	);
};

const ArticleCardsSkeleton = () => {
	return (
		<>
			{[...Array(ARTICLES_PER_PAGE)].map((_, idx) => (
				<ArticleCardSkeleton key={idx} />
			))}
		</>
	);
};

export const ArticleViewSkeleton = () => {
	return (
		<ArticleGrid>
			<ArticleCardsSkeleton />
		</ArticleGrid>
	);
};
