"use client";

import { TabBar } from "@/components/ui/TabBar";
import type { DatasourceEntry } from "@frend-digital/storyblok/server";
import { useQueryParams } from "@frend-digital/ui/next";
import { startTransition, Suspense, useOptimistic } from "react";
import styles from "./index.module.css";

export const UnsuspendedArticleFilters = ({ data }: { data: DatasourceEntry[] }) => {
	const searchParams = useQueryParams({
		shallow: false,
	});

	const value = searchParams.get("filter") || "all";

	const [optimisticValue, setOptimisticValue] = useOptimistic(value);

	return (
		<div data-layout="section" className={styles.filterWrapper}>
			<TabBar
				value={optimisticValue}
				className={styles.filter}
				onValueChange={(e) => {
					startTransition(() => {
						setOptimisticValue(e);
						if (e === "all") return searchParams.delete("filter");
						searchParams.set("filter", e);
					});
				}}
				tabs={[
					{ name: "View all", value: "all" },
					...data.map(({ name, value }) => ({ name, value })),
				]}
			/>
		</div>
	);
};

export const ArticleFilters: typeof UnsuspendedArticleFilters = (props) => {
	return (
		<Suspense>
			<UnsuspendedArticleFilters {...props} />
		</Suspense>
	);
};
