/* eslint-disable custom-rules/no-hardcoded-strings */

import { Skeleton } from "@/components/ui/Skeleton";
import { Text } from "@/components/ui/Text";
import styles from "./index.module.css";

export const ArticleCardSkeleton = () => {
	return (
		<div className={styles.root}>
			<Skeleton className={styles.skeletonImage} />
			<div className={styles.cardFooter}>
				<dl className={styles.footerInfo}>
					<dt>Article title</dt>
					<Skeleton fitContent>
						<Text variant="body3" bold asChild>
							<dd>Lorem, ipsum dolor.</dd>
						</Text>
					</Skeleton>
					<dt>Description</dt>
					<Skeleton width="100%" height="fit-content">
						<Text variant="body3" asChild>
							<dd>l</dd>
						</Text>
					</Skeleton>
					<Skeleton width="100%" height="fit-content">
						<Text variant="body3" asChild>
							<dd>l</dd>
						</Text>
					</Skeleton>
					<Skeleton width="100%" height="fit-content">
						<Text variant="body3" asChild>
							<dd>l</dd>
						</Text>
					</Skeleton>
				</dl>
			</div>
		</div>
	);
};
